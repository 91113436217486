export enum SocketMethods {
  CONNECT_CLIENT = 'ConnectClient',
  CLIENT_CONNECTED = 'ClientConnected',
  CLIENT_DISCONNECTED = 'ClientDisconnected',

  CONNECT_SCANNER = 'ConnectScanner',
  SCANNER_CONNECTED = 'ScannerConnected',
  SCANNER_DISCONNECTED = 'ScannerDisconnected',
}

export enum ClientSocketEvent {
  REQUEST_RECORD_TOKEN = 'client requests scanner to record token',
  REQUEST_READ_NFC_ID = 'client requests scanner to read nfc id',
}

export interface ClientSocketEventData {
  [ClientSocketEvent.REQUEST_RECORD_TOKEN]: string
  [ClientSocketEvent.REQUEST_READ_NFC_ID]: null
}

export enum ScannerSocketEvent {
  WRITE_TAG = 'scanner write a tag',
  READ_ID = 'scanner read id',
}

export interface ScannerSocketEventData {
  [ScannerSocketEvent.WRITE_TAG]: null
  [ScannerSocketEvent.READ_ID]: string
}

export enum SocketConnectionType {
  CLIENT = 'client',
  SCANNER = 'scanner',
}
