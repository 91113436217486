export const useData = (): {
  error: Ref<string>
  connected: Ref<boolean>
  connecting: Ref<boolean>
  relatedConnection: Ref<boolean>
} => {
  const error = ref<string>('')

  const connected = ref<boolean>(false)
  const connecting = ref<boolean>(false)
  const relatedConnection = ref<boolean>(false)

  return {
    error,
    connected,
    connecting,
    relatedConnection,
  }
}
