import type { HubConnection } from '@microsoft/signalr'
import type {
  ClientSocketEvent,
  ClientSocketEventData,
  ScannerSocketEvent,
  ScannerSocketEventData,
} from '../types'

type ClientCommandSubscription = <K extends ClientSocketEvent>(
  event: K,
  callback: (data: ClientSocketEventData[K]) => void,
) => void

type ScannerCommandSubscription = <K extends ScannerSocketEvent>(
  event: K,
  callback: (data: ScannerSocketEventData[K]) => void,
) => void

type ErrorCallback = (message: string) => void

interface ClientCommand {
  on: ClientCommandSubscription
  off: ClientCommandSubscription
  init(onError: ErrorCallback): void
  destroy(): void
  send: <K extends ClientSocketEvent>(
    event: K,
    payload: ClientSocketEventData[K],
  ) => Promise<void>
}

interface ScannerCommand {
  on: ScannerCommandSubscription
  off: ScannerCommandSubscription
  init(onError: ErrorCallback): void
  destroy(): void
  send: <K extends ScannerSocketEvent>(
    event: K,
    payload: ScannerSocketEventData[K],
  ) => Promise<void>
}

function useCommandFunction(
  connection: HubConnection,
  events: typeof ClientSocketEvent,
): ClientCommand
function useCommandFunction(
  connection: HubConnection,
  events: typeof ScannerSocketEvent,
): ScannerCommand
function useCommandFunction(
  connection: HubConnection,
  events: typeof ClientSocketEvent | typeof ScannerSocketEvent,
): ClientCommand | ScannerCommand {
  type Command = ReturnType<typeof useCommandFunction>
  type AddListener = Command['on']
  type RemoveListener = Command['off']
  type SubscriptionCallback = Parameters<AddListener>[1]

  const { t } = useI18n()

  const commands = Object.fromEntries(
    Object.values(events).map<[string, Array<SubscriptionCallback>]>((key) => [
      key,
      [],
    ]),
  )

  const on: AddListener = (event, callback) => {
    commands[event].push(callback as SubscriptionCallback)
  }

  const off: RemoveListener = (event, callback): void => {
    const index = commands[event].findIndex((listener) => listener === callback)
    if (index >= 0) commands[event].splice(index, 1)
  }

  const init = (onError: ErrorCallback): void => {
    connection.on('Command', (data) => {
      try {
        const { command } = JSON.parse(data)

        if (typeof command !== 'string') return

        commands[command]?.forEach((listener) => {
          const event = JSON.parse(data)
          listener(event.payload)
        })
      } catch (e) {
        onError(e instanceof Error ? e.message : t('socket.unexpectedError'))
      }
    })
  }

  const destroy = (): void => {
    connection.off('Command')
  }

  const send: Command['send'] = (command, payload) =>
    connection.invoke(
      'Send',
      JSON.stringify({
        command,
        payload,
      }),
    )

  connection.onclose(() => {
    Object.keys(commands).forEach((event) => {
      commands[event] = []
    })
  })

  return { on, off, init, destroy, send }
}

export const useCommand = useCommandFunction
