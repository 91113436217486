import { useCommand } from './shared/useCommand'
import { useConnection } from './shared/useConnection'
import { useData } from './shared/useData'
import { ClientSocketEvent, ScannerSocketEvent, SocketMethods } from './types'

export const useSocketScannerMode = () => {
  const { t } = useI18n()
  const toast = useToast()
  const connection = useConnection()
  const { error, connecting, connected, relatedConnection } = useData()
  const scanner = useCommand(connection, ScannerSocketEvent)
  const client = useCommand(connection, ClientSocketEvent)

  const app = useApp()

  connection.onclose(() => {
    connected.value = false
    connecting.value = false
    relatedConnection.value = false
    canRetry.value = false

    connection.off(SocketMethods.SCANNER_CONNECTED)
    connection.off(SocketMethods.CLIENT_CONNECTED)
    connection.off(SocketMethods.CLIENT_DISCONNECTED)
  })

  const connect = async (): Promise<void> => {
    try {
      connecting.value = true

      await connection.start()

      connection.on(SocketMethods.SCANNER_CONNECTED, disconnect)
      connection.on(SocketMethods.CLIENT_CONNECTED, onClientConnection)
      connection.on(SocketMethods.CLIENT_DISCONNECTED, onClientDisconnection)

      await connection.invoke(SocketMethods.CONNECT_SCANNER)

      scanner.init((errorMessage) => {
        error.value = errorMessage
      })

      client.init((errorMessage) => {
        error.value = errorMessage
      })

      connected.value = true
    } catch (e) {
      error.value = t('socket.connectionError')
    } finally {
      connecting.value = false
    }
  }

  const disconnect = (): void => {
    app.message('cancelNFC')
    scanner.destroy()
    client.destroy()
    connection.stop()
    error.value = ''
  }

  const onClientConnection = (): void => {
    client.off(ClientSocketEvent.REQUEST_RECORD_TOKEN, recordToken)
    client.off(ClientSocketEvent.REQUEST_READ_NFC_ID, readToken)

    relatedConnection.value = true

    client.on(ClientSocketEvent.REQUEST_RECORD_TOKEN, recordToken)
    client.on(ClientSocketEvent.REQUEST_READ_NFC_ID, readToken)
  }

  const onClientDisconnection = (): void => {
    app.message('cancelNFC')
    error.value = ''
    relatedConnection.value = false
  }

  let retryNfcId: string = ''

  const canRetry = ref<boolean>(false)

  let retry: null | (() => Promise<void>) = null

  const recordToken = async (nfcId: string): Promise<void> => {
    retryNfcId = ''
    error.value = ''
    canRetry.value = false

    retry = () => recordToken(retryNfcId)

    try {
      try {
        await app.message('cancelNFC')
        await app.message('writeNFC', nfcId)
      } catch (e) {
        retryNfcId = nfcId

        error.value =
          e instanceof Error ? e.message : t('socket.unexpectedError')

        canRetry.value = true
        return
      }

      await scanner.send(ScannerSocketEvent.WRITE_TAG, null)

      toast.success('socket.successfullyWriteToTag')
    } catch (e) {
      console.log(e)
      error.value = e instanceof Error ? e.message : t('socket.unexpectedError')
    } finally {
      await app.message('cancelNFC')
    }
  }

  const readToken = async (): Promise<void> => {
    error.value = ''
    canRetry.value = false

    retry = () => readToken()

    let id = ''

    try {
      try {
        await app.message('cancelNFC')
        id = await app.message('readNFC')

        if (!id) throw new Error(t('scanner.error.notFound'))
      } catch (e) {
        canRetry.value = true

        error.value =
          e instanceof Error
            ? e.message
            : typeof e === 'string'
              ? e
              : t('socket.unexpectedError')
        return
      }

      await scanner.send(ScannerSocketEvent.READ_ID, id)

      toast.success('socket.successfullyReadTag')
    } catch (e) {
      error.value =
        e instanceof Error
          ? e.message
          : typeof e === 'string'
            ? e
            : t('socket.unexpectedError')
    } finally {
      await app.message('cancelNFC')
    }
  }

  return {
    data: reactive({ error }),
    state: reactive({ connecting, connected, relatedConnection, canRetry }),

    actions: {
      connect,
      disconnect,
      retry: () => {
        if (retry) retry()
        else error.value = 'Cannot be retried'
      },
    },
  }
}
