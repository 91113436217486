import { ApiError, type DtoRefugeeDetails } from '../sdk/api'
import { useCommand } from './shared/useCommand'
import { useConnection } from './shared/useConnection'
import { useData } from './shared/useData'
import { ClientSocketEvent, ScannerSocketEvent, SocketMethods } from './types'

export const useSocketProfileMode = (
  onRead: (details: DtoRefugeeDetails) => unknown,
) => {
  const { t } = useI18n()
  const connection = useConnection()
  const { error, connecting, connected, relatedConnection } = useData()

  const client = useCommand(connection, ClientSocketEvent)
  const scanner = useCommand(connection, ScannerSocketEvent)

  const nfcId = ref<string>('')

  connection.onclose(() => {
    nfcId.value = ''
    connected.value = false
    connecting.value = false
    relatedConnection.value = false
    canRetry.value = false

    connection.off(SocketMethods.CLIENT_CONNECTED)
    connection.off(SocketMethods.SCANNER_CONNECTED)
    connection.off(SocketMethods.SCANNER_DISCONNECTED)
  })

  const connect = async (): Promise<void> => {
    try {
      error.value = ''
      connecting.value = true

      await connection.start()

      connection.on(SocketMethods.CLIENT_CONNECTED, disconnect)
      connection.on(SocketMethods.SCANNER_CONNECTED, onScannerConnection)
      connection.on(SocketMethods.SCANNER_DISCONNECTED, onScannerDisconnection)

      await connection.invoke(SocketMethods.CONNECT_CLIENT)

      client.init((errorMessage) => {
        error.value = errorMessage
      })

      scanner.init((errorMessage) => {
        error.value = errorMessage
      })

      connected.value = true
    } catch (e) {
      error.value = t('socket.connectionError')
    } finally {
      connecting.value = false
    }
  }

  const disconnect = async (): Promise<void> => {
    client.destroy()
    scanner.destroy()
    await connection.stop()
  }

  const canRetry = ref<boolean>(false)

  const retry = (): Promise<void> => {
    canRetry.value = false
    return fetchDetails()
  }

  const { processing: loading, action: fetchDetails } = useRequest(
    async (api) => {
      try {
        const details = await api.nfc.getRefugeeByNfcId({
          id: nfcId.value,
        })

        await onRead(details)
        disconnect()
      } catch (error) {
        if (error instanceof ApiError && error.status !== 404) {
          canRetry.value = true
        } else {
          await disconnect()
          await connect()
        }

        throw error
      }
    },
  )

  const onScannerConnection = async (): Promise<void> => {
    scanner.off(ScannerSocketEvent.READ_ID, onScannerReadId)

    relatedConnection.value = true

    try {
      scanner.on(ScannerSocketEvent.READ_ID, onScannerReadId)
      await client.send(ClientSocketEvent.REQUEST_READ_NFC_ID, null)
    } catch (e) {
      error.value = e instanceof Error ? e.message : t('socket.unexpectedError')
    }
  }

  const onScannerDisconnection = (): void => {
    relatedConnection.value = false
    scanner.off(ScannerSocketEvent.READ_ID, onScannerReadId)
  }

  const onScannerReadId = async (id: string): Promise<void> => {
    try {
      nfcId.value = id

      scanner.off(ScannerSocketEvent.READ_ID, onScannerReadId)

      await fetchDetails()
    } catch (e) {
      error.value = e instanceof Error ? e.message : t('socket.writeTagError')
    }
  }

  return {
    data: reactive({ error }),
    state: reactive({
      connecting,
      connected,
      loading,
      relatedConnection,
      canRetry,
    }),

    actions: {
      connect,
      disconnect,
      retry,
    },
  }
}
