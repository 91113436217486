import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr'

export const useConnection = (): HubConnection => {
  const runtimeConfig = useRuntimeConfig()
  const url = [runtimeConfig.public.apiBase, '/api/hubs/scanner/']
    .map((value) => value.replace(/^\/+|\/+$/g, ''))
    .join('/')

  const accessToken = useCookie(runtimeConfig.public.accessTokenKey)

  return new HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => accessToken.value || '',
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build()
}
