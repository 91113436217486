import { v4 } from 'uuid'
import { useCommand } from './shared/useCommand'
import { useConnection } from './shared/useConnection'
import { useData } from './shared/useData'
import { ClientSocketEvent, ScannerSocketEvent, SocketMethods } from './types'

export const useSocketClientMode = (
  refugeeId: MaybeRef<string>,
  onWrite: () => unknown,
) => {
  const { t } = useI18n()
  const connection = useConnection()
  const { error, connecting, connected, relatedConnection } = useData()

  const client = useCommand(connection, ClientSocketEvent)
  const scanner = useCommand(connection, ScannerSocketEvent)

  const nfcId = ref<string>('')

  connection.onclose(() => {
    nfcId.value = ''
    connected.value = false
    connecting.value = false
    relatedConnection.value = false
    canRetry.value = false

    connection.off(SocketMethods.CLIENT_CONNECTED)
    connection.off(SocketMethods.SCANNER_CONNECTED)
    connection.off(SocketMethods.SCANNER_DISCONNECTED)
  })

  const connect = async (): Promise<void> => {
    try {
      error.value = ''
      connecting.value = true

      await connection.start()

      connection.on(SocketMethods.CLIENT_CONNECTED, disconnect)
      connection.on(SocketMethods.SCANNER_CONNECTED, onScannerConnection)
      connection.on(SocketMethods.SCANNER_DISCONNECTED, onScannerDisconnection)

      await connection.invoke(SocketMethods.CONNECT_CLIENT)

      client.init((errorMessage) => {
        error.value = errorMessage
      })

      scanner.init((errorMessage) => {
        error.value = errorMessage
      })

      connected.value = true
    } catch (e) {
      error.value = t('socket.connectionError')
    } finally {
      connecting.value = false
    }
  }

  const disconnect = (): void => {
    client.destroy()
    scanner.destroy()
    connection.stop()
  }

  const canRetry = ref<boolean>(false)

  const retry = (): Promise<void> => {
    canRetry.value = false
    return saveTag()
  }

  const { processing: saving, action: saveTag } = useRequest(async (api) => {
    try {
      await api.refugees.createNfcId({
        id: unref(refugeeId),
        requestBody: { nfcId: nfcId.value },
      })
    } catch (error) {
      canRetry.value = true
      throw error
    }

    await onWrite()
    disconnect()

    return 'refugees.details.nfc.success'
  })

  const onScannerConnection = async (): Promise<void> => {
    scanner.off(ScannerSocketEvent.WRITE_TAG, onScannerWriteTag)

    relatedConnection.value = true

    try {
      nfcId.value = v4()

      scanner.on(ScannerSocketEvent.WRITE_TAG, onScannerWriteTag)

      await client.send(ClientSocketEvent.REQUEST_RECORD_TOKEN, nfcId.value)
    } catch (e) {
      error.value = e instanceof Error ? e.message : t('socket.unexpectedError')
    }
  }

  const onScannerDisconnection = (): void => {
    relatedConnection.value = false

    scanner.off(ScannerSocketEvent.WRITE_TAG, onScannerWriteTag)
  }

  const onScannerWriteTag = async (): Promise<void> => {
    try {
      scanner.off(ScannerSocketEvent.WRITE_TAG, onScannerWriteTag)

      await saveTag()
    } catch (e) {
      error.value = e instanceof Error ? e.message : t('socket.writeTagError')
    }
  }

  return {
    data: reactive({ error }),
    state: reactive({
      connecting,
      connected,
      saving,
      relatedConnection,
      canRetry,
    }),

    actions: {
      connect,
      disconnect,
      retry,
    },
  }
}
